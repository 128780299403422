// 电话号
export const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
// 身份证号
export const idCardReg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
// 邮箱
export const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
// URL校验
export const urlReg = /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
// 整数
export const intReg = /^[-+]?\d*$/
// 邮政编号
export const postalNoReg = /^\d{6}$/
// 统一社会信用代码
export const SocialCreditCode = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
// QQ号校验
// 说明：5-11位数字
export const qqReg = /^[1-9][0-9]{4,10}$/
// 微信号
// 说明：6至20位，以字母开头，字母，数字，减号，下划线
export const wxReg = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
// 只含字母的字符串
export const letterReg = /^[a-zA-Z]+$/
// 包含字母或数字的字符串
export const enNumReg = /^[0-9A-Za-z]+$/
// 包含中文的字符串
export const cnReg = /[\u4E00-\u9FA5]/
// 密码
export const pwdReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
// 发放数量限制1000
export const thousand = /^([1-9]|[0-9][0-9]|[1-9][0-9][0-9]|1000)$/
// 替换时间
export const replaceTime = /^(\d{4})(\d{2})(\d{2})$/
// 支持一位大写字母
export const UpperCaseReg = /^[A-Z]+$/

// 不支持特殊字符
export const sepcialCode = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
// 考勤打卡地址不支持特殊字符
export const addressCode = /^[\u4E00-\u9FA5A-Za-z0-9-#()]+$/
// 包含特殊字符校验
export const textReg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、' ']/im

// 不支持数字、字母、特殊符号
export const companyNameReg = /^[\u4E00-\u9FA5]+$/
const regObj = {
  phoneReg,
  idCardReg,
  emailReg,
  urlReg,
  intReg,
  postalNoReg,
  qqReg,
  wxReg,
  letterReg,
  enNumReg,
  cnReg,
  textReg,
  SocialCreditCode,
  pwdReg,
  thousand,
  replaceTime,
  sepcialCode,
  addressCode,
  UpperCaseReg,
  companyNameReg
}

export const regTest = function(type, value) {
  return regObj[type].test(value)
}
