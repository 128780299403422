var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "adjustment beauty-scroll" },
    [
      !_vm.dragValue
        ? _c(
            "div",
            [
              _c("div", { staticClass: "adjustment-heard" }, [
                _c(
                  "div",
                  { staticClass: "adjustment-heard-left" },
                  [
                    _c(
                      "appForm",
                      [
                        _c(
                          "template",
                          { slot: "customButton" },
                          [
                            _c(
                              "a-dropdown",
                              {
                                attrs: { type: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "overlay",
                                      fn: function () {
                                        return [
                                          _c(
                                            "a-menu",
                                            _vm._l(
                                              _vm.singleList,
                                              function (item, index) {
                                                return _c(
                                                  "a-menu-item",
                                                  {
                                                    key: index,
                                                    on: {
                                                      click: _vm.openDrawer,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1086958181
                                ),
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      prefix: "add-one",
                                      suffix: "left",
                                    },
                                  },
                                  [_vm._v(" 单个调整 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "customButton" },
                          [
                            _c(
                              "a-dropdown",
                              {
                                attrs: { type: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "overlay",
                                      fn: function () {
                                        return [
                                          _c(
                                            "a-menu",
                                            _vm._l(
                                              _vm.functionList,
                                              function (item, index) {
                                                return _c(
                                                  "a-menu-item",
                                                  {
                                                    key: index,
                                                    on: { click: _vm.openAll },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2224795435
                                ),
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      prefix: "add-one",
                                      suffix: "left",
                                    },
                                  },
                                  [_vm._v(" 批量调整 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: {
                          prefix: "reduce-one",
                          disabled: _vm.selectedRowKeys.length < 1,
                        },
                        on: { click: _vm.openDeleteBox },
                      },
                      [_vm._v(" 批量删除 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "adjustment-heard-right" },
                  [
                    _c("appForm", {
                      attrs: {
                        "to-list": _vm.formList,
                        "search-placeholder": "请搜索姓名/工号",
                        "screen-roster-list": _vm.screenBalanceList,
                      },
                      on: {
                        getSearchCallback: _vm.getSearchCallback,
                        screenRosterParams: _vm.screenRosterParams,
                        exportFileList: _vm.exportFileList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("custom-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  "row-key": "balanceDetailId",
                  pagination: {
                    current: _vm.pager.pageNum,
                    pageSize: _vm.pager.pageSize,
                    total: _vm.total,
                    showTotal: function (total) {
                      return "共 " + total + " 条数据"
                    },
                    showQuickJumper: true,
                    showSizeChanger: true,
                  },
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    columnWidth: 30,
                    onChange: _vm.onSelectChange,
                    getCheckboxProps: function (record) {
                      return {
                        props: {
                          disabled: record.effectStatus === 1,
                        },
                      }
                    },
                  },
                  "selection-mode": _vm.selectionMode,
                },
                on: { change: _vm.loadDataList },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
                      },
                    },
                    {
                      key: "staffName",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("CbTableAvater", {
                            attrs: {
                              "staff-id": scope.record.staffId,
                              "on-job-id": scope.record.onJobId,
                              "table-name": scope.record.staffName,
                              title: scope.record.staffName,
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "amount",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.record.amount + "小时")),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "effectStatus",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c("div", { staticClass: "flex-align-center" }, [
                            scope.record.effectStatus == true
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "ellipsis",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("已生效")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "ellipsis",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("未生效")]
                                ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "operation",
                      fn: function (ref) {
                        var scope = ref.scope
                        return [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "link",
                                disabled: scope.record.effectStatus === 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.record)
                                },
                              },
                            },
                            [_vm._v(" 修改 ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3911433345
                ),
              }),
              _c(
                "Modal",
                {
                  attrs: {
                    closable: true,
                    "mask-closable": false,
                    visible: _vm.adjustDrawer,
                    title: _vm.title,
                    size: "normal",
                    "overflow-auto": "",
                  },
                  on: { cancel: _vm.onClose, ok: _vm.onSubmit },
                },
                [
                  _c("k-form-build", {
                    ref: "KFB",
                    attrs: {
                      value:
                        _vm.formType === "edit"
                          ? _vm.reviseList
                          : _vm.singleIssue,
                      "text-display": true,
                    },
                    on: { change: _vm.change },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: {
              title: _vm.text + "假期",
              "import-template":
                _vm.text === "批量发放"
                  ? "vacation_adjustment_grant"
                  : "vacation_adjustment_deduct",
            },
            on: { toggleValue: _vm.toggleValue },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }